import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";

Vue.use(Vuex);
import {distanceBetweenPoints, distanceBetweenPointsMeters} from "../utils";
import {getDeviceUUID} from "./device-id";

function calculateDistance(companyCoordinates, currentPosition) {
    if(currentPosition.lat && currentPosition.lng) {
        const a = companyCoordinates;
        const b = currentPosition;
        const d = distanceBetweenPoints(a, b);
        const rounded = Math.round(d);
        return rounded;
    }
    return null;
}

const apiurl = url => window.SAPlatform.server.url + "/wp-json/kodinpro/v1" + url;
function subscriptionValid(data) {
    const now = new Date().getTime() / 1000;
    return data.subscribedUntil > now;
}
export const store = new Vuex.Store({
    state: {
        currentPosition: {
            lat: undefined,
            lng: undefined
        },
        isTester: false,
        companies: [],
        companiesSorted: false,
        regions: [],
        requests: [],
        searchText: "",
        bookings: [],
        requestInterest: {},
        companyBookings: [],
        workCategories: [],
        allWorkCategories: [],
        workSchedule: [],
        user: null,
        uuid: null,
        isBrowser: !("cordova" in window),
        contractor: null,
        customer: null,
        subscriptionValidity: undefined,
        subscriptionInfo: null
    },
    getters: {
        headers: state => {
            if(state.uuid === null) return {};
            return {"X-App-Uuid": state.uuid};
        },
        loggedIn: state => state.user !== null,
        getCompanyById: state => id => state.companies.find(company => company.id === id),
        getCategoryById: state => id => state.workCategories.find(cat => cat.id === id),
        mainWorkCategories: state => state.workCategories.filter(cat => cat.parent === 0),
        openedCompanyBookings: state => state.companyBookings.filter(item => ["booked", "company_accepted", "rejected", "customer_deleted"].includes(item.booking.status)),
        expiredCompanyBookings: state => state.companyBookings.filter(item => item.booking.status === "expired"),
        acceptedCompanyBookings: state => state.companyBookings.filter(item => item.booking.status === "done"),
        getCategoryByIdDeep: state => id => state.allWorkCategories.find(cat => cat.id.toString() === id.toString())
    },
    mutations: {
        sortCompanies(state) {
            state.companies = state.companies.map(company => {
                company.distance = calculateDistance(company.coordinates, state.currentPosition);
                return company;
            }).sort((a, b) => Number(a.distance) - Number(b.distance));
            state.companiesSorted = true;
        },
        setCurrentPosition(state, position) {
            state.currentPosition = position;
        },
        setWorkSchedule(state, events) {
            state.workSchedule = events;
        },
        setUser(state, user) {
            state.user = user;
            console.log("setUser", user);
            if(user && typeof user.get === "function") {
                if(user.get("is_tester") == 1) {
                    state.isTester = true;
                }
            }
        },
        setSearchText(state, searchText) {
            state.searchText = searchText;
        },
        setContractor(state, contractor) {
            state.contractor = contractor;
        },
        setCustomer(state, customer) {
            state.customer = customer;
        },
        addRequest(state, request) {
            state.requests.push(request);
        },
        setRequests(state, requests) {
            state.requests = requests;
        },
        setCompanyBookings(state, bookings) {
            state.companyBookings = bookings;
        },
        setRequestInterest(state, data) {
            state.requestInterest = data;
        },
        setSubscriptionValidity(state, validity) {
            state.subscriptionValidity = validity;
        },
        setSubscriptionInfo(state, info) {
            state.subscriptionInfo = info;
        },
        setCompanies(state, companies) {
            state.companies = companies;
        },
        setWorkCategories(state, categories) {
            state.workCategories = categories;
            state.allWorkCategories = categories.flatMap(category => [category, ...category.subCategories]);
        },
        setRegions(state, regions) {
            state.regions = regions;
        },
        addBookings(state, bookings) {
            state.bookings = state.bookings.concat(bookings);
        },
        setBookings(state, bookings) {
            state.bookings = bookings;
        },
        deleteBooking(state, bookings) {
            let ids = bookings;
            if(!Array.isArray(ids)) {
                ids = [bookings];
            }

            state.bookings = state.bookings.filter(booking => !ids.includes(booking.id));
        },
        updateCompanyCalendar(state, calendarData) {
            for(const key in calendarData) {
                state.contractor.company[key] = calendarData[key];
            }
        },
        removeBooking(state, booking) {
            const bookings = state.bookings;
            for(let index = 0; index < bookings.length; index++) {
                const element = bookings[index];
                if(booking.id === element.id) {
                    state.bookings.splice(index, 1);
                    return;
                }
            }
        },
        setUUID(state, uuid) {
            state.uuid = uuid;
            console.info(`Set device uuid to ${uuid}`);
        }
    },
    actions: {
        calculateBookingDistance(ctx, opt) {
            const req = ctx.state.requests.find(r => r.id === opt.requestId);
            if(!req) return null;

            const booking = req.bookings.find(r => r.id === opt.bookingId);
            if(!booking) return null;

            const d = distanceBetweenPointsMeters(req.coordinates, booking.company.coordinates);
            let rounded = Math.round(d);

            if(rounded < 1000) return `${rounded} M`;

            rounded = (rounded / 1000).toFixed(2);
            return `${rounded} KM`;
        },
        async getBookingDistance(ctx, {id}) {
            const url = apiurl(`/booking/${id}/distance`);
            const resp = await axios.get(url);
            return resp.data.distance;
        },
        async deleteCalendarEvent(ctx, {id}) {
            const url = apiurl(`/contractor/me/events/${id}`);
            await axios.delete(url);
        },
        async createCalendarEvent(ctx, event) {
            const url = apiurl`/contractor/me/events`;
            const resp = await axios.post(url, event);
        },
        async loadWorkSchedule(ctx) {
            const url = apiurl`/contractor/me/events?type=0`;
            const resp = await axios.get(url);
            const data = resp.data;
            ctx.commit("setWorkSchedule", data.reverse());
        },
        async loadRegions(ctx) {
            const url = apiurl`/region/`;
            const resp = await axios.get(url);
            ctx.commit("setRegions", resp.data);
        },
        async createOfferRequest(ctx, payload) {
            const url = apiurl`/offerRequest`;
            const {data} = await axios.post(url, payload, {headers: ctx.getters.headers});
            ctx.commit("addRequest", data);
            return data;
        },
        async createRequest(ctx, payload) {
            const url = apiurl`/workRequest`;
            try {
                const resp = await axios.post(url, payload, {headers: ctx.getters.headers});
                const request = resp.data;
                ctx.commit("addRequest", request);
                return resp.data;
            } catch(e) {
                throw e;
            }
        },
        async loadCompanies(ctx) {
            const url = apiurl`/company/`;
            const resp = await axios.get(url);
            const data = resp.data;
            ctx.commit("setCompanies", data);
        },
        async loadInterestInfo(ctx) {
            const url = apiurl`/interested`;
            const requests = ctx.state.companyBookings.map(item => item.request.id);
            const ids = requests.join(",");
            const resp = await axios.get(url, {params: {requests: ids}});
            const data = resp.data;
            ctx.commit("setRequestInterest", data);
        },
        async loadCompanyBookings(ctx) {
            const url = apiurl`/company/booking`;
            try {
                const {data} = await axios.get(url);

                ctx.commit("setCompanyBookings", data);
            } catch(e) {
                throw e;
            }
        },
        async deleteBooking(ctx, booking) {
            const url = apiurl(`/booking/${booking.id}`);
            await axios.delete(url, {headers: ctx.getters.headers});
        },
        async updateBooking(ctx, booking) {
            const url = apiurl(`/booking/${booking.id}`);
            await axios.put(url, booking, {headers: ctx.getters.headers});
        },
        async requestEmail(ctx, booking) {
            const url = apiurl(`/booking/email/${booking.id}`);
            const resp = await axios.post(url, booking, {headers: ctx.getters.headers});
        },
        async callCustomer(ctx, booking) {
            const url = apiurl(`/booking/callcustomer/${booking.id}`);
            const resp = await axios.post(url, booking, {headers: ctx.getters.headers});
        },
        async suggestBookingPrice(ctx, {booking, message}) {
            const url = apiurl(`/booking/${booking.id}`);
            try {
                await axios.put(url, {message, status: "company_accepted"});
            } catch(e) {
                throw e;
            }
        },
        async showMessage(ctx, {booking}) {
            const url = apiurl(`/booking/message/${booking.id}`);
            const resp = await axios.post(url, booking, {headers: ctx.getters.headers});
        },
        async loadContractorSubscriptionStatus(ctx) {
            const url = apiurl`/company/subscription`;
            const {data} = await axios.get(url);
            ctx.commit("setSubscriptionValidity", subscriptionValid(data));
            ctx.commit("setSubscriptionInfo", data);
        },
        async loadContractorInfo(ctx) {
            const url = apiurl`/contractor/me`;
            const {data} = await axios.get(url);
            console.log("loadContractorInfo", data);
            ctx.commit("setContractor", data);
        },
        async updateContractor(ctx, contractor) {
            const url = apiurl`/contractor/me`;
            const {data} = await axios.put(url, contractor, {headers: ctx.getters.headers});
            ctx.commit("setContractor", data);
        },
        async updateCompany(ctx, company) {
            const url = apiurl`/company`;
            const {data} = await axios.put(url, company, {headers: ctx.getters.headers});

            const contractor = ctx.state.contractor;
            contractor.company = data;
            ctx.commit("setContractor", contractor);
        },
        async loadCustomerInfo(ctx) {
            const url = apiurl`/customer/me`;
            const {data} = await axios.get(url);
            ctx.commit("setCustomer", data);
        },
        async updateBookingStatus(ctx, {booking, status}) {
            const url = apiurl(`/booking/${booking.id}`);
            await axios.put(url, {status}, {headers: ctx.getters.headers});
            ctx.dispatch("loadRequests");
        },
        async addBooking(ctx, {company, requestId}) {
            try {
                console.log("bookCompany");
                const url = apiurl(`/workRequest/${requestId}/booking`);
                const {data} = await axios.post(url, {company}, {headers: ctx.getters.headers});
                ctx.commit("addBookings", [data]);
            } catch(e) {
                throw e;
            }
        },
        async acceptBooking(ctx, {booking, request}) {
            try {
                console.log("acceptBooking");
                const url = apiurl(`/request/${request.id}/accepted`);
                await axios.post(url, {booking: booking.id}, {headers: ctx.getters.headers});
            } catch(e) {
                throw e;
            }
        },
        async loadRequests(ctx) {
            console.log("loadRequests");
            const url = apiurl`/request`;
            const resp = await axios.get(url, {headers: ctx.getters.headers});
            const {data} = resp;
            ctx.commit("setRequests", data);
            return data;
        },
        async loadBookings(ctx, requests) {
            let bookings = [];
            const promises = requests.map(async request => {
                const url = apiurl(`/workRequest/${request.id}/booking`);
                return axios.get(url, {headers: ctx.getters.headers});
            });
            const responses = await Promise.all(promises);
            bookings = responses.map(response => response.data);
            bookings = bookings.flat(1);
            ctx.commit("addBookings", bookings);
        },
        async loadCategories(ctx) {
            const url = apiurl`/workCategory`;
            const query = {};

            if(ctx.state.isTester) {
                query.test = true;
            }

            const resp = await axios.get(url, {params: query});
            const {data} = resp;
            ctx.commit("setWorkCategories", data);
        },
        async logOut(ctx) {
            await window.SAPlatform.user.LogOut({redirect: "/main-customer/"});
            ctx.commit("setContractor", null);
            ctx.commit("setUser", null);
        },
        async loadUuid(context) {
            const uuid = await getDeviceUUID();
            context.commit("setUUID", uuid);
        }
    }
});
